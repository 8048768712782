<template>
	<div v-if="loading">Loading...</div>
</template>

<script>
import GetVehiclesQuery from '@/_srcv2/pages/deliveries-route-lists/vehicle/graphql/GetVehiclesQuery.graphql'
import { useQuery } from '@vue/apollo-composable'

import useVehiclePageState from '@/_srcv2/pages/deliveries-route-lists/vehicle/store/useVehiclePageState.js'
export default {
	name: 'GetVehicleTableDataComponent',
	setup() {
		const {
			vehiclesRows,
			showGetVehicleTableDataComponent,
			showVehiclesTableComponent,
		} = useVehiclePageState()
		const { onResult, onError, loading } = useQuery(GetVehiclesQuery)
		onResult((result) => {
			console.log(result.data.drivers)
			vehiclesRows.value = result.data.vehicles
			console.log('vehiclesRows.value', vehiclesRows.value)
			showGetVehicleTableDataComponent.value = false
			showVehiclesTableComponent.value = true
		})
		onError((error) => {
			console.log(error)
		})
		return { loading }
	},
}
</script>

<style lang="scss" scoped></style>
