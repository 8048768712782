/** @format */

import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const state = reactive({
	showGetVehicleTableDataComponent: true,
	showVehiclesTableComponent: false,
	showPopup: false,
	vehiclesRows: '',
	vehiclesColumns: [
		{
			label: 'ID',
			field: 'vehicle_id',
		},
		{
			label: 'Brand',
			field: 'vehicle_brand',
		},
		{
			label: 'Edit',
			field: 'edit',
			width: '50px',
			sortable: false,
		},
	],
})

export default function useVehiclePageState() {
	return {
		...toRefs(state),
	}
}
