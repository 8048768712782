<template>
	<div>
		<V3Table :columns="vehiclesColumns" :rows="vehiclesRows">
			<div slot="header">
				<div>Active Vehicles</div>
			</div>
			<div slot="footer">
				<div>
					<b-button
						class="margin-top-30 margin-left-15 width-180"
						style="background-color: rgb(182, 90, 92); color: white"
						@click="onAddVehicle"
						:disabled="disableButtons"
					>
						Add New Vehicle
					</b-button>
				</div>
			</div>
		</V3Table>
		<div v-if="showPopup">
			<V3Popup :closePopup="onClose">
				<div slot="header">
					Add New Vehicle - Fill the form to enter new vehicle details
				</div>
				<div slot="body">Add New Vehicle</div>
				<div slot="footer">Add New Vehicle</div>
			</V3Popup>
		</div>
	</div>
</template>

<script>
import V3Table from '@/_srcv2/components/v3/v3-table/V3Table.vue'
import V3Popup from '@/_srcv2/components/v3/v3-popup/V3Popup.vue'
import useVehiclePageState from '@/_srcv2/pages/deliveries-route-lists/vehicle/store/useVehiclePageState.js'
import { ref } from '@vue/composition-api'

export default {
	name: 'VehiclesTableComponent',
	components: { V3Table, V3Popup },
	setup() {
		const { vehiclesRows, vehiclesColumns, showPopup } = useVehiclePageState()
		const disableButtons = ref(false)
		const onAddVehicle = () => {
			console.log('onAddVehicle')
			disableButtons.value = true
			showPopup.value = true
			setTimeout(() => {
				disableButtons.value = false
			}, 1000)
		}
		const onClose = () => {
			showPopup.value = false
		}
		return {
			vehiclesRows,
			vehiclesColumns,
			onAddVehicle,
			disableButtons,
			showPopup,
			onClose,
		}
	},
}
</script>

<style lang="scss" scoped></style>
