<template>
	<div>
		<LayoutDefaultNew>
			<SubNavbar page="Delivery" />
			<div>
				<div v-if="showGetVehicleTableDataComponent">
					<GetVehicleTableDataComponent />
				</div>
				<div v-if="showVehiclesTableComponent">
					<VehiclesTableComponent />
				</div>
			</div>
		</LayoutDefaultNew>
	</div>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import GetVehicleTableDataComponent from '@/_srcv2/pages/deliveries-route-lists/vehicle/components/GetVehicleTableDataComponent.vue'
import VehiclesTableComponent from '@/_srcv2/pages/deliveries-route-lists/vehicle/components/VehiclesTableComponent.vue'
import useVehiclePageState from '@/_srcv2/pages/deliveries-route-lists/vehicle/store/useVehiclePageState.js'
export default {
	name: 'AddEditVehicle',
	components: {
		SubNavbar,
		GetVehicleTableDataComponent,
		VehiclesTableComponent,
	},
	setup() {
		const { showGetVehicleTableDataComponent, showVehiclesTableComponent } =
			useVehiclePageState()
		return {
			showGetVehicleTableDataComponent,
			showVehiclesTableComponent,
		}
	},
}
</script>

<style lang="scss" scoped></style>
